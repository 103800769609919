<template>
  <div class="Partner">
    <Header back="返回" title="意向客户" index="首页" titleOne="合同管理" titleTwo="意向客户" beforeTitle="新增/编辑" />
    <div class="content">
      <h1>客户信息</h1>
      <el-form
        :rules="baseRule"
        ref="baseRule"
        :model="baseform"
        label-width="80px"
        :label-position="labelPosition"
      >
        <el-form-item label="企业名称" prop="company_name">
          <el-input v-model="baseform.company_name" placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="企业类型" prop="com_type">
          <el-select v-model="baseform.com_type" placeholder="请选择企业类型">
            <el-option label="有限责任公司" :value="1">有限责任公司</el-option>
            <el-option label="股份有限公司" :value="2">股份有限公司</el-option>
            <el-option label="国企" :value="3">国企</el-option>
            <el-option label="外商投资企业" :value="4">外商投资企业</el-option>
            <el-option label="独资企业" :value="5">独资企业</el-option>
            <el-option label="个体工商户" :value="6">个体工商户</el-option>
            <el-option label="联营企业" :value="7">联营企业</el-option>
            <el-option label="集体所有制" :value="8">集体所有制</el-option>
            <el-option label="有限合" :value="9">有限合伙</el-option>
            <el-option label="普通合伙" :value="10">普通合伙</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册时间" prop="register_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="baseform.register_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="客户级别" prop="level">
          <el-select v-model="baseform.level" placeholder="请选择">
            <el-option label="A" value="A">A</el-option>
            <el-option label="B" value="B">B</el-option>
            <el-option label="C" value="C">C</el-option>
            <el-option label="D" value="D">D</el-option>
            <el-option label="E" value="E">E</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属区域" prop="province_id">
          <el-select v-model="baseform.province_id" placeholder="请选择省" @change="getcity">
            <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="city_id">
          <el-select v-model="baseform.city_id" placeholder="请选择市" @change="gettown">
            <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="town_id">
          <el-select v-model="baseform.town_id" placeholder="请选择区" @change="getstreet">
            <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="baseform.block_id" placeholder="请选择镇/模块">
            <el-option v-for="item in street" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属行业" prop="industry_one">
          <el-select
            v-model="baseform.industry_one"
            placeholder="请选择所属行业"
            @change="getSecondIndustry"
          >
            <el-option
              v-for="item in oneindustry"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="industry_two">
          <el-select v-model="baseform.industry_two" placeholder="请选择所属行业类型">
            <el-option
              v-for="item in twoindustry"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经营状态" prop="status">
          <el-select v-model="baseform.status" placeholder="请选择经营状态">
            <el-option label="在业" :value="1">在业</el-option>
            <el-option label="存续" :value="2">存续</el-option>
            <el-option label="正常" :value="3">正常</el-option>
            <el-option label="注销" :value="4">注销</el-option>
            <el-option label="筹建" :value="5">筹建</el-option>
            <el-option label="清算" :value="6">清算</el-option>
            <el-option label="迁入" :value="7">迁入</el-option>
            <el-option label="迁出" :value="8">迁出</el-option>
            <el-option label="停业" :value="9">停业</el-option>
            <el-option label="撤销" :value="10">撤销</el-option>
            <el-option label="吊销" :value="11">吊销</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册资本（万元）" prop="register_capital">
          <el-input v-model="baseform.register_capital" placeholder="请输入注册资本"></el-input>
        </el-form-item>
        <el-form-item label="登记机关">
          <el-input v-model="baseform.register_org" placeholder="请输入登记机关"></el-input>
        </el-form-item>
        <el-form-item label="法人代表">
          <el-input v-model="baseform.legal_person" placeholder="请输入法人代表"></el-input>
        </el-form-item>
        <el-form-item label="客户联系人">
          <el-input v-model="baseform.custom_contact" placeholder="请输入客户联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="baseform.custom_tel" placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item label="专利数量">
          <el-input v-model="baseform.patent_count" placeholder="请输入专利数量"></el-input>
        </el-form-item>
        <el-form-item label="软著数量">
          <el-input v-model="baseform.soft_count" placeholder="请输入软著数量"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="商标数量">
          <el-input v-model="baseform.brand_count" placeholder="请输入商标数量"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="注册地址" style="width: 48%" placeholder="请输入注册地址">
          <el-input v-model="baseform.register_address"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" style="width: 48%" placeholder="请输入公司地址">
          <el-input v-model="baseform.address"></el-input>
        </el-form-item>
        <el-form-item label="经营范围" style="width: 100%">
          <el-input
            type="textarea"
            v-model="baseform.jyfw"
            :autosize="{ minRows: 5, maxRows: 5 }"
            placeholder="请输入经营范围"
          ></el-input>
        </el-form-item>
        <el-form-item label="跟进情况" style="width: 100%">
          <el-input
            type="textarea"
            v-model="baseform.follow_up"
            :autosize="{ minRows: 5, maxRows: 5 }"
            placeholder="请输入跟进情况"
          ></el-input>
        </el-form-item>
        <el-form-item label="拜访情况" style="width: 100%">
          <el-input
            type="textarea"
            v-model="baseform.visit"
            :autosize="{ minRows: 5, maxRows: 5 }"
            placeholder="请输入拜访情况"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button
          type="primary"
          :loading="baseBtnLoading"
          @click="baseSubmit"
          :disabled="isDisable"
        >确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      baseBtnLoading: false,//新增编辑保存按钮动画
      isDisable: false,
      activeName: "info",
      labelPosition: "top",
      input: "",
      //first
      baseform: {
        company_name: "",
        com_type: "",
        register_time: "",
        register_capital: "",
        province_id: "",
        city_id: "",
        town_id: "",
        block_id: "",
        industry_one: "",
        industry_two: "",
        status: "",
        register_org: "",
        legal_person: "",
        custom_contact: "",
        custom_tel: "",
        level: "",
        patent_count: "",
        soft_count: "",
        brand_count: "",
        register_address: "",
        address: "",
        jyfw: "",
        follow_up: "",
        visit: "",
        com_id: "1",
      },
      province: [],
      city: [],
      town: [],
      street: [],
      oneindustry: [],
      twoindustry: [],
      baseRule: {
        company_name: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        register_time: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        province_id: [
          {
            required: true,
            message: "请选择省",
            trigger: "change",
          },
        ],
        city_id: [
          {
            required: true,
            message: "请选择市",
            trigger: "change",
          },
        ],
        town_id: [
          {
            required: true,
            message: "请选择区",
            trigger: "change",
          },
        ],
        com_type: [
          {
            required: true,
            message: "请选择企业类型",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择经营状态",
            trigger: "change",
          },
        ],
        industry_one: [
          {
            required: true,
            message: "请选择所属行业",
            trigger: "change",
          },
        ],
        industry_two: [
          {
            required: true,
            message: "请选择所属行业类型",
            trigger: "change",
          },
        ],
        level: [
          {
            required: true,
            message: "请选择客户级别",
            trigger: "change",
          },
        ],
      },
    };
  },
  created () {
    this.getprovince();
    this.getFirstIndustry();
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  watch: {
    "baseform.province_id": {
      handler (val, oval) {
        this.getcity(val);
      },
      deep: true,
    },
    "baseform.city_id": {
      handler (val, oval) {
        this.gettown(val);
      },
      deep: true,
    },
    "baseform.town_id": {
      handler (val, oval) {
        this.getstreet(val);
      },
      deep: true,
    },
    "baseform.industry_one": {
      handler (val, oval) {
        this.getSecondIndustry(val);
      },
      deep: true,
    },
  },
  mounted () {
    if (this.meber_id) {
      // 这个是一个页面查看的接口
      this.axios
        .get("/api/company/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          // console.log(JSON.stringify(res.data.city_id))
          // res.data.city_id = JSON.stringify(res.data.city_id)
          // res.data.town_id = JSON.stringify(res.data.town_id)
          // res.data.block_id = JSON.stringify(res.data.block_id)
          // res.data.register_time = JSON.stringify(res.data.register_time)
          console.log();
          this.baseform = res.data;
        });
    }
  },
  methods: {
    //first
    baseSubmit () {
      this.$refs.baseRule.validate((vaild) => {
        if (vaild) {
          this.baseBtnLoading = true
          this.axios.get('/api/company/select_company', { params: { keywords: this.baseform.company_name } }).then((res) => {
            if (res.code == 1) {
              // console.log(res.data.find((item) => item.company_name == this.baseform.company_name).company_name)
              if (res.data.find((item) => item.company_name == this.baseform.company_name).company_name == this.baseform.company_name) {
                this.$message.warning('企业名称已存在')
                this.baseBtnLoading = false
              }
            } else {
              this.baseBtnLoading = true
              this.axios
                .post("/api/company/store", this.baseform)
                .then((res) => {
                  this.$message({
                    type: "success",
                    message: "保存成功!",
                  });
                  this.$router.push("/Potential/index");
                  this.isDisable = true;
                  this.baseBtnLoading = false
                })
                .catch((res) => {
                  console.log("err:", res);
                  this.baseBtnLoading = false
                });
            }
          })
        } else {
          this.$message.warning('请完善必填信息')
        }
      });
    },
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
        });
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
        });
    },
    getstreet (id) {
      this.axios
        .get("/api/region/getBlock", {
          params: {
            town_id: id,
          },
        })
        .then((res) => {
          this.street = res.data;
        });
    },
    getFirstIndustry () {
      this.axios.get("/api/region/getFirstIndustry").then((res) => {
        this.oneindustry = res.data;
      });
    },
    getSecondIndustry (id) {
      this.axios
        .get("/api/region/getSecondIndustry", {
          params: {
            industry_id: id,
          },
        })
        .then((res) => {
          this.twoindustry = res.data;
        });
    },
  },
};
</script>

<style scoped="scoped">
.Partner dl {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.Partner dl dt {
  display: inline-block;
  width: 25%;
  color: #a6abc7;
}

.Partner dl dd {
  display: inline-block;
  width: 25%;
  margin: 0 !important;
  font-weight: bold;
}

.Partner dl dt:nth-child(2) {
  margin-right: 2% !important;
}

.Partner dl dd:nth-child(2) {
  margin-right: 2% !important;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.Import {
  float: right;
  color: #909399;
  background: #edeef4;
  border: none;
  margin-right: 2%;
}
</style>
